<template>
  <div style="display: none"></div>
</template>

<script>
export default {
  name: 'OMapPolyline',

  inject: ['onMap'],

  props: {
    name: {
      type: String,
      default: '',
    },

    path: {
      type: Array,
      default: () => [],
    },

    color: {
      type: String,
      default: 'blue',
    },

    opacity: {
      type: Number,
      default: 1,
    },

    weight: {
      type: Number,
      default: 2,
    },

    dashed: {
      type: Boolean,
      default: false,
    },

    strokeColor: {
      type: String,
      default: 'white',
    },

    strokeWeight: {
      type: Number,
    },

    strokeOpacity: {
      type: Number,
      default: 1,
    },

    showDirection: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.$polyline = this.createPolyline(
      this.path,
      this.color,
      this.weight,
      this.opacity,
      this.dashed ? '4' : null,
    )

    if (this.showDirection) {
      require('leaflet-textpath')

      this.$polyline.setText('►', {
        repeat: true,
        offset: 4,
        attributes: { fill: this.color, 'font-size': 12, 'letter-spacing': 50 },
      })
    }

    if (this.strokeWeight) {
      this.$backgroundPolyline = this.createPolyline(
        this.path,
        this.strokeColor,
        this.strokeWeight,
        this.strokeOpacity,
        null,
      )
    }

    if (this.$backgroundPolyline) {
      this.createEventListener(this.$backgroundPolyline)
      this.bindTooltip(this.$backgroundPolyline)
    }

    this.createEventListener(this.$polyline)
    this.bindTooltip(this.$polyline)

    this.onMap.then((map) => {
      if (this.$backgroundPolyline) {
        map.addLayer(this.$backgroundPolyline)
      }

      map.addLayer(this.$polyline)
    })
  },

  watch: {
    path(newPath) {
      if (this.$backgroundPolyline) {
        this.$backgroundPolyline.setLatLngs(newPath)
      }

      this.$polyline.setLatLngs(newPath)
    },

    name(newName) {
      if (this.$backgroundPolyline) {
        this.$backgroundPolyline.setTooltipContent(newName)
      }

      this.$polyline.setTooltipContent(newName)
    },

    weight(newWeight) {
      this.$polyline.setStyle({
        weight: newWeight,
      })
    },

    dashed(newDashed) {
      this.$polyline.setStyle({
        dashArray: newDashed ? '4' : null,
      })
    },

    color(newColor) {
      this.$polyline.setStyle({
        color: newColor,
      })
    },

    opacity(newOpacity) {
      this.$polyline.setStyle({
        opacity: newOpacity,
      })
    },

    strokeColor(newStrokeColor) {
      this.$backgroundPolyline.setStyle({
        color: newStrokeColor,
      })
    },

    strokeWeight(newStrokeWeight) {
      this.$backgroundPolyline.setStyle({
        weight: newStrokeWeight,
      })
    },

    strokeOpacity(newStrokeOpacity) {
      this.$backgroundPolyline.setStyle({
        opacity: newStrokeOpacity,
      })
    },
  },

  methods: {
    createPolyline(path, color, weight, opacity, dashed) {
      const { Polyline } = require('leaflet')

      return new Polyline(path, {
        color: color,
        weight: weight,
        opacity: opacity,
        dashArray: dashed,
      })
    },

    bindTooltip(polyline) {
      polyline.bindTooltip(this.name, {
        direction: 'top',
        sticky: true,
      })
    },

    createEventListener(polyline) {
      const { DomEvent } = require('leaflet')

      polyline.on('click', (e) => {
        DomEvent.stopPropagation(e)
        this.$emit('click', e)
      })
    },
  },

  beforeDestroy() {
    this.onMap.then((map) => {
      map.removeLayer(this.$polyline)

      if (this.$backgroundPolyline) {
        map.removeLayer(this.$backgroundPolyline)
      }
    })
  },
}
</script>

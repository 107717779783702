<template>
  <div>
    <slot name="actions" />
    <live-stage-waypoint-generate-start-finish
      :stage-paths="stagePaths"
      :stage-waypoints="stageWaypoints"
      :live-categories="liveCategories"
      @generate="$emit('update:stageWaypoints', [...stageWaypoints, ...$event])"
    />
    <div class="level">
      <div class="level-left">
        <h2 class="title is-5">
          Waypoints
          <span class="has-text-weight-light is-size-6">(Vérifier accès public/privé)</span>
        </h2>
      </div>
      <div class="level-right">
        <div class="level-item">
          <live-stage-waypoint-upload
            :loading="loading"
            :stageWaypoints="stageWaypoints"
            @submit="uploadGpxFile"
          />
        </div>
        <div class="level-item">
          <o-export-button
            :disabled="loading || stageWaypoints.length === 0"
            :file="stageWaypointsGpx"
            file-type="gpx"
            :file-name="`${live.name}-${liveStage.name}-waypoints`"
            @export="exportGpx"
            @exported="stageWaypointsGpx = null"
          />
        </div>
      </div>
    </div>
    <live-stage-waypoint-table
      :loading="loading"
      :live="live"
      :live-stage="liveStage"
      :stage-waypoints="stageWaypoints"
      :live-categories="liveCategories"
      @submit="updateWaypoint"
      @submitAll="updateAllCheckedWaypoints"
      @deleteAll="deleteAllCheckedWaypoints"
      @updatePosition="updatePosition"
      @deleteWaypoint="deleteWaypoint"
    />
    <live-stage-waypoint-caption v-show="stageWaypoints.length" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OExportButton from '@components/ExportButton'
import LiveStageWaypointTable from './LiveStageWaypointTable.vue'
import LiveStageWaypointUpload from './LiveStageWaypointUpload.vue'
import LiveStageWaypointCaption from './LiveStageWaypointCaption.vue'
import LiveStageWaypointGenerateStartFinish from './LiveStageWaypointGenerateStartFinish.vue'

export default {
  name: 'EditLiveStageWaypointTab',

  components: {
    OExportButton,
    LiveStageWaypointCaption,
    LiveStageWaypointUpload,
    LiveStageWaypointTable,
    LiveStageWaypointGenerateStartFinish,
  },

  props: {
    live: {
      type: Object,
      required: true,
      validator: (v) => ['name'].every((key) => key in v),
    },
    liveStage: {
      type: Object,
      required: true,
    },
    liveCategories: {
      type: Array,
      required: true,
    },
    stageWaypoints: {
      type: Array,
      required: true,
    },
    stagePaths: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      stageWaypointsGpx: null,
    }
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async uploadGpxFile(gpxFile) {
      this.loading = true

      try {
        const stageWaypoints = await this.$services.liveStageWaypointService.replace(
          this.liveStage.id,
          gpxFile,
        )

        this.$emit('update:stageWaypoints', stageWaypoints)

        this.addToastMessage({
          text: `Les waypoints de "${this.liveStage.name}" ont été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async updateWaypoint(waypoint) {
      const stageWaypoints = this.stageWaypoints.map((stageWaypoint) =>
        stageWaypoint.id === waypoint.id ? waypoint : stageWaypoint,
      )

      this.$emit('update:stageWaypoints', stageWaypoints)
    },

    async updateAllCheckedWaypoints(waypoints) {
      this.loading = true

      try {
        await this.$services.liveStageWaypointService.updateAll({
          liveStageId: this.liveStage.id,
          waypoints,
        })

        this.addToastMessage({
          text: `Les waypoints ont été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      await this.fetchWaypoints()
    },

    async updatePosition({ liveStageWaypoint, position }) {
      this.loading = true

      try {
        await this.$services.liveStageWaypointService.update(liveStageWaypoint.id, {
          ...liveStageWaypoint,
          position,
        })

        this.addToastMessage({
          text: `Le waypoint "${liveStageWaypoint.name}" a été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      await this.fetchWaypoints()
    },

    async deleteAllCheckedWaypoints(waypointIds) {
      this.loading = true

      try {
        await this.$services.liveStageWaypointService.deleteAll({
          waypointIds,
        })

        this.addToastMessage({
          text: `Les waypoints ont été supprimés.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      await this.fetchWaypoints()
    },

    async deleteWaypoint(waypoint) {
      this.loading = true

      try {
        const deletedWaypoint = await this.$services.liveStageWaypointService.delete(waypoint.id)
        const stageWaypoints = this.stageWaypoints.filter((wpt) => wpt.id !== deletedWaypoint.id)

        this.$emit('update:stageWaypoints', stageWaypoints)

        this.addToastMessage({
          text: `Le waypoint "${deletedWaypoint.name}" a été supprimé.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async exportGpx() {
      this.loading = true

      try {
        this.stageWaypointsGpx = await this.$services.liveStageWaypointService.exportGpx({
          liveStageId: this.liveStage.id,
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async fetchWaypoints() {
      this.loading = true

      try {
        const stageWaypoints = await this.$services.liveStageWaypointService.getAll(
          this.liveStage.id,
        )

        this.$emit('update:stageWaypoints', stageWaypoints)
      } catch (err) {
        this.addToastMessage({
          text: err.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },
  },
}
</script>

<template>
  <div style="display: none" />
</template>

<script>
import styles from '@styles/_variables.scss'

export default {
  name: 'OMapZoneCircleMarker',

  inject: ['onMap'],

  props: {
    latLng: {
      type: Array,
      default: null,
    },

    radius: {
      type: Number,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },

    color: {
      type: String,
      default: styles.gray,
    },

    weight: {
      type: Number,
      default: 2,
    },
  },

  mounted() {
    const { Circle, LayerGroup } = require('leaflet')

    this.$circleMarker = new Circle(this.latLng, {
      color: 'white',
      fillOpacity: 0.2,
      fillColor: styles[this.color],
      radius: this.radius,
      weight: this.weight,
      className: 'o-map-zone-circle-marker',
    })

    this.$circleMarkerBorder = new Circle(this.latLng, {
      fill: false,
      color: styles[this.color],
      radius: this.radius,
      weight: this.weight,
      opacity: 0.6,
      className: 'o-map-zone-circle-marker',
    })

    this.createTooltip(this.$circleMarker, this.label)
    this.createTooltip(this.$circleMarkerBorder, this.label)

    this.onMap.then((map) => {
      this.$layerGroup = new LayerGroup([this.$circleMarker, this.$circleMarkerBorder])
      map.addLayer(this.$layerGroup)
    })
  },

  watch: {
    label(newLabel) {
      this.$circleMarker.setTooltipContent(newLabel)
    },

    color(newColor) {
      this.$circleMarker.setStyle({
        fillColor: styles[newColor],
      })

      this.$circleMarkerBorder.setStyle({
        color: styles[newColor],
      })
    },
  },

  methods: {
    createTooltip(marker, label) {
      marker.bindTooltip(label, {
        direction: 'top',
        offset: [0, -25],
      })
    },
  },

  beforeDestroy() {
    this.onMap.then((map) => {
      map.removeLayer(this.$layerGroup)
    })
  },
}
</script>

<style lang="scss">
@import 'variables';

.o-map-zone-circle-marker {
  filter: drop-shadow(0px 0px 2px $color-secondary-lightest);

  &:hover {
    cursor: unset;
  }
}
</style>

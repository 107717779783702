<template>
  <form novalidate="true" @submit.prevent="submit">
    <div class="columns is-multiline">
      <b-field
        label="Nom*"
        :message="fields.name.error"
        :type="fields.name.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-input v-model="fields.name.value" type="text" />
      </b-field>
      <b-field
        label="Label"
        :message="fields.label.error"
        :type="fields.label.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-input
          v-model="fields.label.value"
          type="text"
          maxlength="6"
          :icon-right="fields.label.value ? 'close-circle' : ''"
          :icon-right-clickable="!!fields.label.value"
          @icon-right-click="fields.label.value = null"
        />
      </b-field>
    </div>
    <div class="columns is-multiline">
      <b-field
        grouped
        group-multiline
        label="Couleur*"
        :message="fields.color.error"
        :type="fields.color.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-radio-button
          v-for="color in colors"
          :key="color.value"
          v-model="fields.color.value"
          :native-value="color.value"
          :type="`is-${fields.color.value}`"
        >
          {{ color.label }}
        </b-radio-button>
      </b-field>
      <b-field
        label="Icône*"
        :message="fields.icon.error"
        :type="fields.icon.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <o-icon-picker v-model="fields.icon.value" :icons="icons" />
      </b-field>
    </div>
    <div class="columns is-multiline">
      <b-field
        label="Rayon"
        :message="fields.radius.error"
        :type="fields.radius.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <o-dropdown
          v-model="fields.radius.value"
          :items="radiuses"
          @input="(value) => (fields.radius.value = value)"
        >
          <template v-slot:label="{ label }">
            {{ radius(label) }}
          </template>
          <template v-slot:item="{ item }">
            {{ radius(item) }}
          </template>
        </o-dropdown>
      </b-field>
      <b-field
        v-if="fields.radius.value"
        label="Role"
        :message="fields.role.error"
        :type="fields.role.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <o-dropdown v-model="fields.role.value" :items="roles" @input="fields.role.value = $event">
          <template v-slot:label="{ label }">
            {{ label || '—' }}
          </template>
          <template v-slot:item="{ item }">
            {{ item || '—' }}
          </template>
        </o-dropdown>
      </b-field>
    </div>
    <div v-if="fields.radius.value" class="columns is-multiline">
      <b-field
        v-if="fields.radius.value"
        label="Catégorie de validation"
        class="column is-full-mobile"
      >
        <b-select v-model="fields.liveCategoryId.value" expanded>
          <option :value="null">—</option>
          <option v-for="category in liveCategories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </b-select>
      </b-field>
      <div class="column is-full-mobile">
        <b-field grouped group-multiline class="columns">
          <b-field
            :label="`Date de début (${utcOffset})`"
            :message="fields.enabledAt.error"
            :type="fields.enabledAt.error !== null ? 'is-danger' : ''"
            class="column is-full-mobile"
          >
            <o-date-time-picker
              v-model="fields.enabledAt.value"
              :min-datetime="liveStage.startedAt"
              :max-datetime="liveStage.endedAt"
              :timezone="live.timezone"
            />
          </b-field>
        </b-field>
        <div>Fuseau horaire: {{ live.timezone }}</div>
      </div>
    </div>
    <div class="columns is-multiline">
      <b-field
        label="Type d'accès"
        :message="fields.accessType.error"
        :type="fields.accessType.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-field>
          <b-radio-button v-model="fields.accessType.value" native-value="public">
            <span>Public</span>
          </b-radio-button>
          <b-radio-button v-model="fields.accessType.value" native-value="private">
            <span>Privé</span>
          </b-radio-button>
        </b-field>
      </b-field>
    </div>
    <hr />
    <b-field>
      <div class="buttons">
        <b-button
          native-type="submit"
          type="is-primary"
          :disabled="disabledSubmit"
          :loading="loading"
        >
          Modifier
        </b-button>
        <b-button type="is-danger" @click="$emit('abort')">Annuler</b-button>
      </div>
    </b-field>
  </form>
</template>

<script>
import { getTimezoneOffset } from 'date-fns-tz'
import { mapActions } from 'vuex'
import { LIVE_STAGE_WAYPOINT_COLORS } from '@constants/waypoint/color'
import { iconNames } from '@data/icons'
import { RADIUSES } from '@constants/waypoint/radiuses'
import { WAYPOINT_ROLE_FINISH, WAYPOINT_ROLE_START } from '@constants/waypoint/role'
import ODropdown from '@components/Dropdown'
import OIconPicker from '@components/IconPicker'
import ODateTimePicker from '@components/DateTimePicker'

export default {
  name: 'EditLiveStageWaypointForm',

  components: {
    ODropdown,
    OIconPicker,
    ODateTimePicker,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
    liveStage: {
      type: Object,
      required: true,
    },
    liveStageWaypoint: {
      type: Object,
      required: true,
    },
    liveStageWaypoints: {
      type: Array,
      required: true,
    },
    liveCategories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      fields: {
        name: {
          value: '',
          error: null,
        },
        color: {
          value: '',
          error: null,
        },
        icon: {
          value: '',
          error: null,
        },
        accessType: {
          value: '',
          error: null,
        },
        radius: {
          value: '',
          error: null,
        },
        role: {
          value: null,
          error: null,
        },
        label: {
          value: '',
          error: null,
        },
        liveCategoryId: {
          value: null,
          error: null,
        },
        enabledAt: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.colors = LIVE_STAGE_WAYPOINT_COLORS
    this.icons = iconNames
    this.radiuses = [null, ...RADIUSES]
    this.start = WAYPOINT_ROLE_START
    this.finish = WAYPOINT_ROLE_FINISH
    this.roles = [null, WAYPOINT_ROLE_START, WAYPOINT_ROLE_FINISH]
  },

  watch: {
    liveStageWaypoint: {
      immediate: true,
      handler: 'load',
    },

    'fields.radius.value'(radius) {
      if (!radius) {
        this.fields.role.value = null
        this.fields.enabledAt.value = null
        this.fields.liveCategoryId.value = null
      }
    },

    'fields.name.value'(name) {
      this.fields.name.error = name?.length ? null : 'Nom requis'
    },

    'fields.icon.value'(icon) {
      this.fields.icon.error = icon ? null : 'Icône requise'
    },

    fieldRoleError(error) {
      this.fields.role.error = error
    },

    fieldLiveCategoryIdError(error) {
      this.fields.liveCategoryId.error = error
    },
  },

  computed: {
    utcOffset() {
      const offset = getTimezoneOffset(this.live.timezone) / 1000 / 60 / 60
      return `UTC${offset >= 0 ? '+' : ''}${offset}`
    },

    fieldRoleError() {
      const waypointWithSameRole = this.liveStageWaypoints.find((waypoint) => {
        const condition =
          waypoint.role &&
          waypoint.role === this.fields.role.value &&
          waypoint.id !== this.liveStageWaypoint.id
        const optionalCondition =
          waypoint.liveCategoryId === this.fields.liveCategoryId.value ||
          waypoint.liveCategoryId === null

        return this.fields.liveCategoryId.value ? condition && optionalCondition : condition
      })

      return waypointWithSameRole
        ? `Rôle déjà attribué au waypoint ${waypointWithSameRole.name}`
        : null
    },

    disabledSubmit() {
      return Object.values(this.fields).find((field) => field.error !== null) || this.loading
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    load() {
      this.fields.name.value = this.liveStageWaypoint.name
      this.fields.icon.value = this.liveStageWaypoint.icon
      this.fields.color.value = this.liveStageWaypoint.color
      this.fields.accessType.value = this.liveStageWaypoint.accessType
      this.fields.radius.value = this.liveStageWaypoint.radius
      this.fields.role.value = this.liveStageWaypoint.role
      this.fields.label.value = this.liveStageWaypoint.label
      this.fields.liveCategoryId.value = this.liveStageWaypoint.liveCategoryId
      this.fields.enabledAt.value = this.liveStageWaypoint.enabledAt || this.liveStage.startedAt
    },

    async submit() {
      this.loading = true

      try {
        const updatedWaypoint = await this.$services.liveStageWaypointService.update(
          this.liveStageWaypoint.id,
          {
            ...this.liveStageWaypoint,
            name: this.fields.name.value,
            icon: this.fields.icon.value,
            color: this.fields.color.value,
            accessType: this.fields.accessType.value,
            label: this.fields.label.value?.length > 0 ? this.fields.label.value : null,
            radius: this.fields.radius.value > 0 ? this.fields.radius.value : null,
            role: this.fields.role.value,
            liveCategoryId: this.fields.liveCategoryId.value,
            enabledAt: this.fields.enabledAt.value,
          },
        )

        this.$emit('submit', updatedWaypoint)

        this.addToastMessage({
          text: `Le waypoint "${updatedWaypoint.name}" a été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    radius(radius) {
      return radius ? `${radius}m` : '—'
    },
  },
}
</script>

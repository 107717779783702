<template>
  <div class="columns is-multiline is-gapless">
    <span class="column is-full-mobile is-narrow mr-2">
      <b-icon size="is-small" type="is-info" icon="copyright" /> Checkpoint
    </span>
    <span class="column is-full-mobile is-narrow">
      <b-icon size="is-small" type="is-warning" icon="exclamation-triangle" />
      Caractères maximum recommandés atteints (30)
    </span>
  </div>
</template>

<script>
export default {
  name: 'LiveStageWaypointCaption',
}
</script>

<template>
  <div class="box">
    <form novalidate="true" @submit.prevent="submit">
      <h2 class="title is-4">Ajouter un contact d'alerte</h2>
      <b-field grouped group-multiline label="*Type(s)">
        <p v-for="alertType in alertTypes" :key="alertType.value" class="control">
          <b-button
            :type="fields.types.value.includes(alertType.value) ? 'is-primary is-light' : ''"
            :label="alertType.label"
            @click="toggleType(alertType.value)"
          />
        </p>
      </b-field>
      <b-field
        label="Nom*"
        :message="fields.name.error"
        :type="fields.name.error !== null ? 'is-danger' : ''"
      >
        <b-input v-model="fields.name.value" type="text" />
      </b-field>
      <b-field
        label="Email"
        :message="fields.email.error"
        :type="fields.email.error !== null ? 'is-danger' : ''"
      >
        <b-input v-model="fields.email.value" type="email" />
      </b-field>
      <b-field
        label="Téléphone"
        :message="fields.phoneNumber.error"
        :type="fields.phoneNumber.error !== null ? 'is-danger' : ''"
      >
        <b-input v-model="fields.phoneNumber.value" type="text" />
      </b-field>
      <b-field>
        <div class="buttons">
          <b-button
            native-type="submit"
            type="is-primary"
            :loading="loading"
            :disabled="isFormInvalid"
            label="Ajouter"
          />
          <b-button
            type="is-danger"
            tag="router-link"
            :to="{ name: 'live.edit.vehicle.list', params: { tab: 2 } }"
            label="Annuler"
          />
        </div>
      </b-field>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  ALERT_TYPE_MEDICAL_HELP,
  ALERT_TYPE_OK,
  ALERT_TYPE_TECHNICAL_HELP,
} from '@constants/alert/type'
import { isEmail } from '@helpers/isEmail'

export default {
  name: 'EditLiveAddAlertContact',

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      fields: {
        name: {
          value: null,
          error: null,
        },
        types: {
          value: [],
          error: null,
        },
        phoneNumber: {
          value: null,
          error: null,
        },
        email: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.alertTypes = [
      { label: 'Médical', value: ALERT_TYPE_MEDICAL_HELP },
      { label: 'Technique', value: ALERT_TYPE_TECHNICAL_HELP },
      { label: 'Ok', value: ALERT_TYPE_OK },
    ]
  },

  watch: {
    'fields.types.value'(types) {
      this.fields.types.error = types.length > 0 ? null : 'Au moins un type est requis'
    },

    'fields.name.value'(name) {
      this.fields.name.error = name?.length > 0 ? null : 'Le nom est requis'
    },

    'fields.email.value'(email) {
      this.fields.email.error = email && isEmail(email) ? null : 'Email incorrect'
    },

    hasEmailOrPhoneNumber(hasValue) {
      this.fields.phoneNumber.error = hasValue ? null : 'Téléphone ou email requis'
    },
  },

  computed: {
    hasEmailOrPhoneNumber() {
      return this.fields.phoneNumber.value?.length > 0 || this.fields.email.value?.length > 0
    },

    isFormInvalid() {
      return (
        !!Object.values(this.fields).find((field) => field.error !== null) ||
        this.fields.name.value === null ||
        this.fields.types.value.length === 0 ||
        !this.hasEmailOrPhoneNumber
      )
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      if (this.isFormInvalid) {
        return
      }

      this.loading = true

      try {
        const fields = {
          liveId: this.live.id,
          name: this.fields.name.value,
          email: this.fields.email.value?.length > 0 ? this.fields.email.value : null,
          phoneNumber:
            this.fields.phoneNumber.value?.length > 0 ? this.fields.phoneNumber.value : null,
        }

        const liveAlertContacts = this.fields.types.value.map((type) => ({
          ...fields,
          type,
        }))

        await this.$services.liveAlertContactService.createMany(liveAlertContacts)

        this.addToastMessage({
          text:
            liveAlertContacts.length === 1
              ? "Le contact d'alerte a été créé."
              : "Les contacts d'alertes ont été créés.",
          type: 'is-success',
        })

        this.$router.push({
          name: 'live.edit.vehicle.list',
          params: { tab: 2 },
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    toggleType(alertType) {
      this.fields.types.value = this.fields.types.value.includes(alertType)
        ? this.fields.types.value.filter((type) => type !== alertType)
        : (this.fields.types.value = [...this.fields.types.value, alertType])
    },
  },
}
</script>

<template>
  <div v-if="liveStage" class="columns is-multiline">
    <b-collapse v-model="showMap" class="column is-full">
      <template #trigger="props">
        <div role="button" class="card-header">
          <h2 class="card-header-title is-size-5">{{ liveStage.name }}</h2>
          <div class="card-header-icon">
            <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
          </div>
        </div>
      </template>
      <live-stage-map
        v-if="showMap"
        ref="map"
        class="card mb-3"
        :stage-routes="stageRoutes"
        :stage-waypoints="stageWaypoints"
        :stage-speed-zones="stageSpeedZones"
      />
    </b-collapse>
    <div class="column is-full">
      <o-tabs :labels="tabItems" :destroy-on-hide="false">
        <template #0>
          <div class="card">
            <div class="card-header">
              <h2 class="card-header-title">Informations de l'étape</h2>
            </div>
            <div class="card-content">
              <edit-live-stage-form :live="live" :liveStage.sync="liveStage" />
            </div>
          </div>
        </template>
        <template #1>
          <upload-live-stage-resources
            :live-stage-id="liveStage.id"
            :stage-paths.sync="stagePaths"
            :public-stage-routes.sync="stageRoutes['public']"
            :private-stage-routes.sync="stageRoutes['private']"
          />
          <edit-live-stage-route-tab
            v-for="accessType in ['public', 'private']"
            :key="'edit-live-stage-route-tab' + accessType"
            :live="live"
            :liveStage="liveStage"
            :accessType="accessType"
            :stage-routes.sync="stageRoutes[accessType]"
          />
          <edit-live-stage-path-tab
            :live="live"
            :liveStage="liveStage"
            :live-categories="liveCategories"
            :stage-paths.sync="stagePaths"
          />
        </template>
        <template #2>
          <edit-live-stage-waypoint-tab
            :live="live"
            :liveStage="liveStage"
            :live-categories="liveCategories"
            :stage-waypoints.sync="stageWaypoints"
            :stage-paths="stagePaths"
          >
            <template #actions>
              <b-button
                type="is-success"
                icon-left="plus"
                label="Nouveau"
                class="mb-4"
                @click="redirectToWaypointForm"
              />
            </template>
          </edit-live-stage-waypoint-tab>
        </template>
        <template #3>
          <edit-live-stage-speed-zone-tab
            :live="live"
            :liveStage="liveStage"
            :stage-speed-zones.sync="stageSpeedZones"
          />
        </template>
      </o-tabs>
    </div>
  </div>
</template>

<script>
import OTabs from '@components/Tabs.vue'
import LiveStageMap from '@views/MapView/LiveStageMap'
import EditLiveStageForm from './forms/EditLiveStageForm.vue'
import EditLiveStageRouteTab from './EditLiveStageRouteTab/index.vue'
import EditLiveStagePathTab from './EditLiveStagePathTab/index.vue'
import EditLiveStageWaypointTab from './EditLiveStageWaypointTab/index.vue'
import EditLiveStageSpeedZoneTab from './EditLiveStageSpeedZoneTab/index.vue'
import UploadLiveStageResources from './UploadLiveStageResources.vue'
import { STAGE_ACCESS_TYPE_PRIVATE, STAGE_ACCESS_TYPE_PUBLIC } from '@constants/stage/accessType'
import { isMobile } from '@helpers/isMobile'

export default {
  name: 'EditLiveEditStageTab',

  components: {
    OTabs,
    LiveStageMap,
    EditLiveStageForm,
    EditLiveStageSpeedZoneTab,
    EditLiveStagePathTab,
    EditLiveStageRouteTab,
    EditLiveStageWaypointTab,
    UploadLiveStageResources,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
    liveStageId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      liveStage: null,
      stageWaypoints: [],
      stageSpeedZones: [],
      stageRoutes: { public: [], private: [] },
      stagePaths: [],
      liveCategories: [],
      showMap: true,
    }
  },

  created() {
    this.tabItems = ['Général', 'Parcours', 'Waypoints', 'Zones de vitesse']

    if (isMobile()) {
      this.showMap = false
    }
  },

  async mounted() {
    this.loading = true

    await this.fetchLiveStage()
    await Promise.all([
      this.fetchWaypoints(),
      this.fetchStageByAccessType(STAGE_ACCESS_TYPE_PRIVATE),
      this.fetchStageByAccessType(STAGE_ACCESS_TYPE_PUBLIC),
      this.fetchStageSpeedZones(),
      this.fetchStagePaths(),
      this.fetchLiveCategories(),
    ])

    this.loading = false
  },

  methods: {
    async fetchLiveStage() {
      try {
        this.liveStage = await this.$services.liveStageService.getById(this.liveStageId)
      } catch (e) {
        this.liveStage = null
      }
    },

    async fetchWaypoints() {
      try {
        this.stageWaypoints = await this.$services.liveStageWaypointService.getAll(
          this.liveStage.id,
        )
      } catch (e) {
        this.stageWaypoints = []
      }
    },

    async fetchStageSpeedZones() {
      try {
        this.stageSpeedZones = await this.$services.liveStageSpeedZoneService.getAll(
          this.liveStage.id,
        )
      } catch (err) {
        this.stageSpeedZones = []
      }
    },

    async fetchStageByAccessType(accessType) {
      try {
        this.stageRoutes[accessType] =
          await this.$services.liveStageRouteService.getAllByAccessType(
            this.liveStage.id,
            accessType,
          )
      } catch (e) {
        this.stageRoutes[accessType] = []
      }
    },

    async fetchStagePaths() {
      try {
        this.stagePaths = await this.$services.liveStagePathService.getAll(this.liveStage.id)
      } catch (err) {
        this.stagePaths = []
      }
    },

    async fetchLiveCategories() {
      try {
        this.liveCategories = await this.$services.liveCategoryService.getAll(this.live.id)
      } catch (err) {
        this.liveCategories = []
      }
    },

    redirectToWaypointForm() {
      this.$router.push({
        name: 'live.edit.stage.edit.waypoint.add',
        query: { bounds: JSON.stringify(this.$refs.map.getBounds()) },
      })
    },
  },
}
</script>

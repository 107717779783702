<template>
  <b-field :label="label">
    <div class="live-stage-timeline">
      <div class="live-stage-timeline_container">
        <b-tooltip
          v-for="item in stageItems"
          :key="item.id"
          position="is-top"
          class="live-stage-timeline_item"
          :style="{
            left: `${item.left}%`,
            width: `${item.width}%`,
          }"
        >
          <template #content>
            <h3 class="has-text-weight-bold has-text-centered">{{ item.name }}</h3>
            <div>Début : {{ item.zonedStartedAt }}</div>
            <div>Fin : {{ item.zonedEndedAt }}</div>
          </template>
          <div class="live-stage-timeline_item_content">{{ item.name }}</div>
        </b-tooltip>
      </div>
      <div
        class="live-stage-timeline_error live-stage-timeline_error--left"
        :style="errorStyle.start"
      />
      <div
        class="live-stage-timeline_error live-stage-timeline_error--right"
        :style="errorStyle.end"
      />
    </div>
  </b-field>
</template>

<script>
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

const TIMELINE_WIDTH = 100

export default {
  name: 'LiveStageTimeline',

  props: {
    live: {
      type: Object,
      required: true,
    },
    liveStages: {
      type: Array,
      required: true,
    },
  },

  computed: {
    utcLiveDate() {
      return {
        startedAt: zonedTimeToUtc(this.live.startedAt.setHours(0, 0, 0), this.live.timezone),
        endedAt: zonedTimeToUtc(this.live.endedAt.setHours(23, 59, 59), this.live.timezone),
      }
    },

    label() {
      return `Chronologie du live: du ${format(
        this.live.startedAt,
        'dd/MM/yyyy HH:mm',
      )} au ${format(this.live.endedAt, 'dd/MM/yyyy HH:mm')}`
    },

    stageItems() {
      return this.liveStages.map((stage) => {
        const start = this.getPositionFromDate(stage.startedAt)
        const end = this.getPositionFromDate(stage.endedAt)

        return {
          id: stage.id,
          name: stage.name,
          zonedStartedAt: this.localeToZonedTime(stage.startedAt, 'dd/MM/yyyy HH:mm'),
          zonedEndedAt: this.localeToZonedTime(stage.endedAt, 'dd/MM/yyyy HH:mm'),
          left: start,
          width: end - start,
        }
      })
    },

    errorStyle() {
      const start = this.getPositionFromDate(this.utcLiveDate.startedAt)
      const end = TIMELINE_WIDTH - this.getPositionFromDate(this.utcLiveDate.endedAt)

      return {
        start: {
          width: `${start}%`,
        },
        end: {
          width: `${end}%`,
        },
      }
    },

    timelineRange() {
      return this.liveStages.reduce(
        ({ start, end }, stage) => {
          if (stage.startedAt < start) {
            start = stage.startedAt
          }
          if (stage.endedAt > end) {
            end = stage.endedAt
          }

          return { start, end }
        },
        { start: this.utcLiveDate.startedAt, end: this.utcLiveDate.endedAt },
      )
    },
  },

  methods: {
    getPositionFromDate(date) {
      return (
        ((date - this.timelineRange.start) / (this.timelineRange.end - this.timelineRange.start)) *
        TIMELINE_WIDTH
      )
    },

    localeToZonedTime(date, dateFormat) {
      const zonedDate = utcToZonedTime(date, this.live.timezone)

      return format(zonedDate, dateFormat)
    },
  },
}
</script>

<style lang="scss">
@import 'variables';

.live-stage-timeline {
  position: relative;
  height: 50px;
  background-color: $color-secondary-lightest;
  padding: $spacing-s 0;

  &_container {
    position: relative;
    height: 100%;
  }

  &_item {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    min-width: 10px;

    &_content {
      height: 100%;
      padding: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $white;
      background-color: rgba($primary-dark, 0.7);
      text-align: center;
      border-left: 1px solid rgba($white, 0.6);
      border-right: 1px solid rgba($white, 0.6);

      &:hover {
        background-color: rgba($primary, 1);
        border-color: $white;
      }
    }
    &:hover {
      z-index: 2;
    }
  }

  &_error {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
    background-color: $warning;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }
}
</style>

import { render, staticRenderFns } from "./LiveStagePathTable.vue?vue&type=template&id=0b4e5b1e"
import script from "./LiveStagePathTable.vue?vue&type=script&lang=js"
export * from "./LiveStagePathTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
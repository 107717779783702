<template>
  <o-tabs :labels="tabItems">
    <template #0>
      <live-vehicle-list
        :live="live"
        :loading="loading"
        :live-vehicles="liveVehicles"
        @submit="loading = true"
        @error="loading = false"
        @import="importVehicles"
        @delete="deleteVehicle"
        @deleteAll="deleteAllVehicles"
      />
    </template>
    <template #1>
      <switch-live-vehicle-tracker
        :live="live"
        :spotTrackers="spotTrackers"
        :queclinkTrackers="queclinkTrackers"
        :live-vehicles="liveVehicles"
        @updateLiveVehicles="updateLiveVehicles"
      />
    </template>
    <template #2>
      <live-alert-contact
        :live="live"
        :live-vehicles.sync="liveVehicles"
        :has-spot-trackers="Object.keys(spotTrackers).length > 0"
      />
    </template>
    <template #3>
      <list-queclink-tracker-configs :live="live" :live-vehicles="liveVehicles" />
    </template>
  </o-tabs>
</template>

<script>
import { mapActions } from 'vuex'
import { QUECLINK_TRACKER_TYPE, SPOT_TRACKER_TYPE } from '@constants/tracker/type.js'
import OTabs from '@components/Tabs.vue'
import LiveVehicleList from './LiveVehicleList.vue'
import LiveAlertContact from './ListLiveAlertContact/index.vue'
import SwitchLiveVehicleTracker from './SwitchLiveVehicleTracker/index.vue'
import ListQueclinkTrackerConfigs from './ListQueclinkTrackerConfigs/index.vue'
import { STATUS_PENDING } from '@constants/live/status'

export default {
  name: 'EditLiveVehiclesTab.vue',

  components: {
    OTabs,
    LiveVehicleList,
    LiveAlertContact,
    SwitchLiveVehicleTracker,
    ListQueclinkTrackerConfigs,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      spotTrackers: {},
      queclinkTrackers: {},
      liveVehicles: [],
    }
  },

  async mounted() {
    await this.fetch()
  },

  computed: {
    tabItems() {
      const tabItems = ['Général', 'Inversion de balises', 'Alertes']

      if (Object.keys(this.queclinkTrackers).length > 0) {
        tabItems.push('Configuration Queclink')
      }

      return tabItems
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async fetch() {
      this.loading = true

      try {
        this.liveVehicles = await this.getLiveVehicles()
      } catch (err) {
        console.error(err)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async getLiveVehicles() {
      const liveVehicles = await this.$services.liveVehicleService.getAll(this.live.id)
      return this.formatLiveVehicles(liveVehicles)
    },

    async fetchLiveCategories() {
      return (await this.$services.liveCategoryService.getAll(this.live.id)).reduce(
        (categories, category) => ({
          ...categories,
          [category.id]: category.name,
        }),
        {},
      )
    },

    async fetchSpotTrackers() {
      return (await this.$services.spotTrackerService.getAllByLiveId(this.live.id)).reduce(
        (spotTrackers, spotTracker) => ({
          ...spotTrackers,
          [spotTracker.id]: {
            id: spotTracker.id,
            type: SPOT_TRACKER_TYPE,
            number: spotTracker.number,
            findmespotProfileId: spotTracker.findmespotProfileId,
            findmespotDeviceName: spotTracker.findmespotDeviceName,
          },
        }),
        {},
      )
    },

    async fetchQueclinkTrackers() {
      return (await this.$services.queclinkTrackerService.getAllByLiveId(this.live.id)).reduce(
        (queclinkTrackers, queclinkTracker) => ({
          ...queclinkTrackers,
          [queclinkTracker.id]: {
            id: queclinkTracker.id,
            type: QUECLINK_TRACKER_TYPE,
            number: queclinkTracker.number,
          },
        }),
        {},
      )
    },

    async formatLiveVehicles(liveVehicles) {
      const [liveCategories, spotTrackers, queclinkTrackers] = await Promise.all([
        await this.fetchLiveCategories(),
        await this.fetchSpotTrackers(),
        await this.fetchQueclinkTrackers(),
      ])

      this.spotTrackers = spotTrackers
      this.queclinkTrackers = queclinkTrackers

      return liveVehicles.map((vehicle) => {
        const trackers = []

        const spotTracker = spotTrackers[vehicle.spotTrackerId]
        if (spotTracker) {
          trackers.push(spotTracker)
        }

        const queclinkTracker = queclinkTrackers[vehicle.queclinkTrackerId]
        if (queclinkTracker) {
          trackers.push(queclinkTracker)
        }

        return {
          ...vehicle,
          categoryName: liveCategories[vehicle.liveCategoryId],
          trackers,
        }
      })
    },

    async deleteVehicle(liveVehicle) {
      this.loading = true

      try {
        const deletedVehicle = await this.$services.liveVehicleService.delete(liveVehicle.id)

        this.liveVehicles = this.liveVehicles.filter((vehicle) => vehicle.id !== deletedVehicle.id)

        this.addToastMessage({
          text: 'Le participant a été supprimé.',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async deleteAllVehicles() {
      this.loading = true

      if (this.live.status !== STATUS_PENDING) {
        this.loading = false

        this.addToastMessage({
          text: 'Le live à déjà commencé',
          type: 'is-danger',
        })

        return
      }

      try {
        await this.$services.liveVehicleService.deleteAll(this.live.id)

        this.liveVehicles = []

        this.addToastMessage({
          text: 'Les participants ont été supprimés.',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async importVehicles(vehicles) {
      this.loading = true

      this.liveVehicles = await this.formatLiveVehicles(vehicles)

      this.addToastMessage({
        text: `La liste des véhicules du live "${this.live.name}" a été importée`,
        type: 'is-success',
      })

      this.loading = false
    },

    async updateLiveVehicles() {
      this.loading = true

      this.liveVehicles = await this.getLiveVehicles()

      this.loading = false
    },
  },
}
</script>

<template>
  <div class="mt-5 pb-5">
    <div class="level">
      <div class="level-left">
        <h3 class="title is-5 level-item">{{ title }}</h3>
      </div>
      <div class="level-right">
        <div class="level-item">
          <live-stage-route-upload
            :loading="loading"
            :stageRoutes="stageRoutes"
            @submit="uploadGpxFile"
          />
        </div>
        <div class="level-item">
          <o-export-button
            :disabled="loading || stageRoutes.length === 0"
            :file="stageRoutesGpx"
            file-type="gpx"
            :file-name="`${live.name}-${liveStage.name}-routes-${accessType}`"
            @export="exportGpx"
            @exported="stageRoutesGpx = null"
          />
        </div>
      </div>
    </div>
    <live-stage-route-table
      :loading="loading"
      :stageRoutes="stageRoutes"
      @submit="updateRoute"
      @submitAll="updateRoutes"
      @updatePosition="updatePosition"
      @delete="deleteRoute"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LiveStageRouteTable from './LiveStageRouteTable.vue'
import LiveStageRouteUpload from './LiveStageRouteUpload.vue'
import OExportButton from '@components/ExportButton'

export default {
  name: 'EditLiveStageRouteTab',

  components: {
    OExportButton,
    LiveStageRouteUpload,
    LiveStageRouteTable,
  },

  props: {
    live: {
      type: Object,
      required: true,
      validator: (v) => ['name'].every((key) => key in v),
    },
    liveStage: {
      type: Object,
      required: true,
    },
    accessType: {
      type: String,
      required: true,
      validator: (value) => ['public', 'private'].indexOf(value) !== -1,
    },
    stageRoutes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      stageRoutesGpx: null,
    }
  },

  computed: {
    title() {
      return this.accessType === 'private' ? 'Parcours privés' : 'Parcours publics'
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async fetch() {
      this.loading = true

      try {
        const stageRoutes = await this.$services.liveStageRouteService.getAllByAccessType(
          this.liveStage.id,
          this.accessType,
        )

        this.$emit('update:stageRoutes', stageRoutes)
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async uploadGpxFile(gpxFile) {
      this.loading = true

      try {
        const stageRoutes = await this.$services.liveStageRouteService.create(
          this.liveStage.id,
          this.accessType,
          gpxFile,
        )

        this.$emit('update:stageRoutes', stageRoutes)

        this.addToastMessage({
          text: `Les parcours de "${this.liveStage.name}" ont été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async updateRoute(route) {
      this.loading = true

      try {
        const updatedRoute = await this.$services.liveStageRouteService.update(route.id, {
          ...route,
        })

        const stageRoutes = this.stageRoutes.map((stageRoute) =>
          stageRoute.id === updatedRoute.id
            ? { ...updatedRoute, pathLength: stageRoute.pathLength }
            : stageRoute,
        )

        this.$emit('update:stageRoutes', stageRoutes)

        this.addToastMessage({
          text: `Le parcours "${updatedRoute.name}" a été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async updateRoutes(routes) {
      this.loading = true

      try {
        await this.$services.liveStageRouteService.updateAll(this.liveStage.id, routes)

        this.addToastMessage({
          text: `Les parcours ont été modifiés.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      await this.fetch()
    },

    async updatePosition({ liveStageRoute, position }) {
      this.loading = true

      try {
        await this.$services.liveStageRouteService.update(liveStageRoute.id, {
          ...liveStageRoute,
          position: position,
        })

        this.addToastMessage({
          text: `La position du parcours "${liveStageRoute.name}" a été modifiée.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      await this.fetch()
    },

    async deleteRoute(route) {
      this.loading = true

      try {
        const deletedRoute = await this.$services.liveStageRouteService.delete(route.id)

        const stageRoutes = this.stageRoutes.filter((r) => r.id !== deletedRoute.id)

        this.$emit('update:stageRoutes', stageRoutes)

        this.addToastMessage({
          text: `Le parcours "${deletedRoute.name}" a été supprimé.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async exportGpx() {
      this.loading = true

      try {
        this.stageRoutesGpx = await this.$services.liveStageRouteService.exportGpx({
          liveStageId: this.liveStage.id,
          accessType: this.accessType,
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },
  },
}
</script>

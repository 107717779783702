<template>
  <div class="mt-5">
    <div class="level">
      <div class="level-left">
        <h2 class="title is-5 level-item">Route complètes (élévation, stats...)</h2>
      </div>
      <div class="level-right">
        <div class="level-item">
          <o-upload
            v-model="gpxFile"
            :loading="loading"
            :label="stagePaths.length === 0 ? 'Importer un GPX' : 'Remplacer le GPX'"
            type="application/gpx+xml"
            @submit="uploadGpxFile"
            @deleteFile="deleteGpxFile"
          />
        </div>
        <div class="level-item">
          <o-export-button
            :disabled="loading || stagePaths.length === 0"
            :file="stagePathsGpx"
            file-type="gpx"
            :file-name="`${live.name}-${liveStage.name}-path`"
            @export="exportGpx"
            @exported="stagePathsGpx = null"
          />
        </div>
      </div>
    </div>
    <live-stage-path-table
      :loading="loading"
      :stagePaths="stagePaths"
      :live-categories="liveCategories"
      @submit="updatePath"
      @delete="deletePath"
    />
  </div>
</template>

<script>
import OUpload from '@components/Upload.vue'
import { mapActions } from 'vuex'
import OExportButton from '@components/ExportButton.vue'
import LiveStagePathTable from './LiveStagePathTable.vue'

export default {
  name: 'EditLiveStagePathTab.vue',

  components: {
    OExportButton,
    OUpload,
    LiveStagePathTable,
  },

  props: {
    live: {
      type: Object,
      required: true,
      validator: (v) => ['name'].every((key) => key in v),
    },
    liveStage: {
      type: Object,
      required: true,
    },
    stagePaths: {
      type: Array,
      required: true,
    },
    liveCategories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      gpxFile: null,
      stagePathsGpx: null,
    }
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async uploadGpxFile() {
      this.loading = true

      try {
        const stagePaths = await this.$services.liveStagePathService.replace(
          this.liveStage.id,
          this.gpxFile,
        )

        this.$emit('update:stagePaths', stagePaths)

        this.addToastMessage({
          text: `La route complète a été mise à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.deleteGpxFile()
      this.loading = false
    },

    deleteGpxFile() {
      this.gpxFile = null
    },

    async exportGpx() {
      this.loading = true

      try {
        this.stagePathsGpx = await this.$services.liveStagePathService.exportGpx({
          liveStageId: this.liveStage.id,
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async updatePath(path) {
      this.loading = true

      try {
        const updatedPath = await this.$services.liveStagePathService.update(
          path.id,
          path.name,
          path.liveCategoryId,
        )

        const stagePaths = this.stagePaths.map((stagePath) =>
          stagePath.id === updatedPath.id ? updatedPath : stagePath,
        )

        this.$emit('update:stagePaths', stagePaths)

        this.addToastMessage({
          text: `La route complète a été mise à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async deletePath(path) {
      this.loading = true

      try {
        const deletedPath = await this.$services.liveStagePathService.delete(path.id)
        const stagePaths = this.stagePaths.filter((path) => path.id !== deletedPath.id)

        this.$emit('update:stagePaths', stagePaths)

        this.addToastMessage({
          text: `La route complète "${deletedPath.name}" a été supprimée.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },
  },
}
</script>

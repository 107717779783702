<template>
  <b-dropdown ref="dropdown" aria-role="list">
    <template #trigger="{ active }">
      <b-tooltip :label="tooltipLabel" position="is-top">
        <b-button
          class="is-success"
          label="Générer un start et un finish"
          type="is-primary"
          :icon-right="active ? 'angle-up' : 'angle-down'"
          :disabled="disabledTrigger"
        />
      </b-tooltip>
    </template>
    <b-dropdown-item custom aria-role="menuitem">
      <b-field label="Route complète">
        <b-select
          v-model="selectedStagePathId"
          placeholder="Sélectionner une route complète"
          expanded
        >
          <option v-for="path in availableStagePaths" :key="path.id" :value="path.id">
            {{ path.name }}
          </option>
        </b-select>
      </b-field>
      <b-field>
        <b-button type="is-primary" :loading="loading" :disabled="disabledSubmit" @click="generate">
          Générer
        </b-button>
      </b-field>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { WAYPOINT_ROLE_FINISH, WAYPOINT_ROLE_START } from '@constants/waypoint/role'
import { mapActions } from 'vuex'

export default {
  name: 'LiveStageWaypointGenerateStartFinish',

  props: {
    stagePaths: {
      type: Array,
      required: true,
    },
    stageWaypoints: {
      type: Array,
      required: true,
    },
    liveCategories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      selectedStagePathId: null,
    }
  },

  watch: {
    'availableStagePaths.length': {
      handler(length) {
        this.selectedStagePathId = length === 1 ? this.availableStagePaths[0].id : null
      },
      immediate: true,
    },
  },

  computed: {
    tooltipLabel() {
      return this.disabledTrigger
        ? 'Cette étape a déjà au moins un start ou un finish'
        : 'Générer un start et un finish depuis une route complète'
    },

    disabledTrigger() {
      return this.hasGlobalStartFinish || !this.availableStagePaths.length
    },

    disabledSubmit() {
      return this.loading || !this.selectedStagePathId
    },

    startFinishWaypoints() {
      return this.stageWaypoints.filter((stageWaypoint) =>
        [WAYPOINT_ROLE_START, WAYPOINT_ROLE_FINISH].includes(stageWaypoint.role),
      )
    },

    hasGlobalStartFinish() {
      return this.startFinishWaypoints.some(
        (waypoint) =>
          (waypoint.role === WAYPOINT_ROLE_START && waypoint.liveCategoryId === null) ||
          (waypoint.role === WAYPOINT_ROLE_FINISH && waypoint.liveCategoryId === null),
      )
    },

    availableStagePaths() {
      if (this.hasGlobalStartFinish) {
        return []
      }

      return this.stagePaths.filter((stagePath) => {
        if (stagePath.liveCategoryId) {
          const category = this.liveCategories.find(
            (liveCategory) => liveCategory.id === stagePath.liveCategoryId,
          )
          if (category && !category.useCheckpoint) {
            return false
          }

          return !this.startFinishWaypoints.some(
            (waypoint) => waypoint.liveCategoryId === stagePath.liveCategoryId,
          )
        }

        return this.startFinishWaypoints.length === 0
      })
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async generate() {
      this.loading = true

      try {
        const waypoints = await this.$services.liveStageWaypointService.generateStartFinish(
          this.selectedStagePathId,
        )

        this.$emit('generate', waypoints)

        this.$refs.dropdown.toggle()

        this.addToastMessage({
          text: `Les waypoints start et finish ont été générés.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },
  },
}
</script>

<template>
  <div class="box">
    <o-table
      :items="stageWaypoints"
      :loading="loading"
      :draggable="!selectedWaypoint"
      :detailed="true"
      :checkable="true"
      :checked-rows="checkedWaypoints"
      @check="checkedWaypoints = $event"
      @drop="
        (droppedOnRowIndex, _, row) =>
          $emit('updatePosition', { liveStageWaypoint: row, position: droppedOnRowIndex })
      "
    >
      <b-table-column v-slot="{ row }" field="accessType" label="Accès" centered>
        <b-icon
          v-if="row.accessType === 'public'"
          size="is-small"
          type="is-success"
          icon="unlock"
        />
        <b-icon
          v-else-if="row.accessType === 'private'"
          size="is-small"
          type="is-danger"
          icon="lock"
        />
      </b-table-column>
      <b-table-column v-slot="{ row }" field="name" label="Nom">
        <b-icon v-show="row.radius" size="is-small" type="is-info" icon="copyright" />
        <b-icon
          v-show="row.role === start"
          size="is-small"
          type="is-success"
          icon="flag-checkered"
        />
        <b-icon
          v-show="row.role === finish"
          size="is-small"
          type="is-danger"
          icon="flag-checkered"
        />
        {{ row.name }}
        <b-icon
          v-show="row.name.length > 30"
          size="is-small"
          type="is-warning"
          icon="exclamation-triangle"
        />
      </b-table-column>
      <b-table-column v-slot="{ row }" field="label" label="Label">
        {{ row.label || '—' }}
      </b-table-column>
      <b-table-column v-slot="{ row }" field="icon" label="Icône">
        <o-icon :name="row.icon" :color="row.color" />
      </b-table-column>
      <b-table-column v-slot="{ row }" field="radius" label="Rayon">
        {{ radius(row.radius) }}
      </b-table-column>
      <b-table-column v-slot="{ row }" field="liveCategoryId" label="Catégorie">
        {{ getCategoryName(row.liveCategoryId) }}
      </b-table-column>
      <b-table-column v-slot="{ row, toggleDetails }" width="100">
        <div class="buttons are-small is-right">
          <b-button type="is-primary" :loading="loading" @click="() => toggleDetails(row)">
            <b-icon icon="edit" />
          </b-button>
          <b-button type="is-danger" :loading="loading" @click="toggleModal(row)">
            <b-icon icon="trash" />
          </b-button>
        </div>
      </b-table-column>

      <template #detail="{ row, toggleDetails }">
        <edit-live-stage-waypoint-form
          :live="live"
          :live-stage="liveStage"
          :live-stage-waypoint="row"
          :live-stage-waypoints="stageWaypoints"
          :live-categories="filteredCategories"
          @submit="
            toggleDetails(row)
            $emit('submit', $event)
          "
          @abort="toggleDetails(row)"
        />
      </template>
    </o-table>
    <o-modal
      :title="modalTitle"
      :isOpen="!!selectedWaypoint && isOpenModal"
      @closeModal="toggleModal"
      @confirmModalAction="deleteWaypoint"
    >
      <template> La suppression d'un waypoint est <strong>définitive</strong>. </template>
    </o-modal>
    <live-stage-waypoint-form
      v-show="checkedWaypoints.length > 0"
      :loading="loading"
      :live-categories="filteredCategories"
      class="mt-2"
      @submit="submitAll"
      @delete="deleteAll"
    />
  </div>
</template>

<script>
import OModal from '@components/Modal.vue'
import OTable from '@components/Table.vue'
import LiveStageWaypointForm from './LiveStageWaypointForm'
import { WAYPOINT_ROLE_FINISH, WAYPOINT_ROLE_START } from '@constants/waypoint/role'
import EditLiveStageWaypointForm from '@views/pages/EditLivePage/forms/EditLiveStageWaypointForm'

export default {
  name: 'EditLiveStageWaypointTable',

  components: {
    OModal,
    OTable,
    LiveStageWaypointForm,
    EditLiveStageWaypointForm,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
    liveStage: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    stageWaypoints: {
      type: Array,
      required: true,
    },
    liveCategories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isOpenModal: false,
      selectedWaypoint: null,
      checkedWaypoints: [],
    }
  },

  created() {
    this.start = WAYPOINT_ROLE_START
    this.finish = WAYPOINT_ROLE_FINISH
  },

  watch: {
    stageWaypoints() {
      this.checkedWaypoints = []
    },
  },

  computed: {
    modalTitle() {
      return `Suppression du waypoint ${this.selectedWaypoint && this.selectedWaypoint.name}`
    },

    filteredCategories() {
      return this.liveCategories.filter((category) => category.useCheckpoint)
    },
  },

  methods: {
    submitAll(fields) {
      const waypoints = this.checkedWaypoints.map((waypoint) => {
        if (fields.radius === null && fields.role !== null) {
          fields.role = null
          fields.liveCategoryId = null
        }

        return { ...waypoint, ...fields }
      })

      this.$emit('submitAll', waypoints)
    },

    deleteAll() {
      const waypointIds = this.checkedWaypoints.map((waypoint) => waypoint.id)
      this.$emit('deleteAll', waypointIds)
    },

    deleteWaypoint() {
      this.$emit('deleteWaypoint', this.selectedWaypoint)
    },

    radius(radius) {
      return radius ? `${radius}m` : '—'
    },

    toggleModal(waypoint = null) {
      this.isOpenModal = !!waypoint
      this.selectedWaypoint = waypoint
    },

    getCategoryName(categoryId) {
      const category = this.filteredCategories.find((cat) => cat.id === categoryId)

      return category?.name ? category.name : '—'
    },
  },
}
</script>
